import '../App.css';
import Header from "../components/common/header";
import Footer from '../components/common/footer';
import OverView from '../components/generals/overview'
import Proxy from '../components/generals/proxy/proxy';
import Bypass from '../components/generals/bypass/bypass';
import HistoryTransactions from '../components/apps/transactions-history';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import LoginForm from "../components/common/loginform";
import SideBar from '../components/common/sidebar';
import TimeLine from '../components/common/timeline';
import MembersManagerment from '../components/apps/members-management';
import UsersAnalytics from '../components/statistical/usersanalytics';
import SitesAnalytics from '../components/statistical/sitesanalytics';
import Managerment from '../components/managerment/managerment';
import EmployeeManagerment from '../components/managerment/employee';
import ClickerSetting from '../components/clicker/clicker';
import Whitelist from '../components/managerment/whitelist';
import ChatBox from '../components/common/chatbox';
import Crypto from '../components/cryto/crypto';
import Tracking from '../components/generals/bypass/tracking';
import Toast from '../components/common/toast';
import LoginBypass from '../components/generals/bypass/login_bypass';





function Dashboard({ currentPage, loadingBarRef }) {
    const [toasts, setToasts] = useState([]);
    const [ws, setWs] = useState(null);
    const [reconnectAttempt, setReconnectAttempt] = useState(0);

    const connectWebSocket = () => {
        const socket = new WebSocket('wss://bypass.saviartmedia.com/ws/proxynotify/');

        socket.onopen = () => {
            console.log('WebSocket Proxy connected');
            setReconnectAttempt(0); // Reset reconnect attempts
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.notification) {
                const notification = data.notification;

                // Chuyển đổi từ bytes sang MB
                const uploadMB = (notification.upload / (1024 * 1024)).toFixed(2);
                const downloadMB = (notification.download / (1024 * 1024)).toFixed(2);

                // Tạo thông báo
                const message = {
                    port: notification.portrequest,
                    domain: notification.domain,
                    upload: uploadMB,
                    download: downloadMB,
                };

                // Gửi đến Toast
                setToast('warning', message);
            }
        };

        socket.onerror = (error) => {
            console.error('Proxy WebSocket error:', error);
        };

        socket.onclose = (event) => {
            console.warn('Proxy WebSocket disconnected:', event.reason);
            if (reconnectAttempt < 5) {
                setTimeout(() => {
                    console.log(`Proxy Attempting to reconnect... (${reconnectAttempt + 1})`);
                    setReconnectAttempt((prev) => prev + 1);
                    connectWebSocket();
                }, 5000); // Thử lại sau 5 giây
            } else {
                console.error('Proxy Max reconnect attempts reached.');
            }
        };

        setWs(socket);
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (ws) ws.close(); // Cleanup WebSocket when component unmounts
        };
    }, []); // Chỉ chạy khi component mount


    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 768);
    useEffect(() => {
        const handleResize = () => {
            // Cập nhật trạng thái dựa trên độ rộng màn hình
            setIsSidebarVisible(window.innerWidth > 768);
        };

        // Đăng ký event listener
        window.addEventListener('resize', handleResize);

        // Dọn dẹp khi component unmounted
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };
    const navigate = useNavigate();
    useEffect(() => {
        // Hiển thị thanh tiến trình
        loadingBarRef.current.continuousStart();
        loadingBarRef.current.complete();
        // Kiểm tra trạng thái đăng nhập
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            const isTokenExpired = decodedToken.exp * 1000 < new Date().getTime();

            if (isTokenExpired) {
                localStorage.clear();
                // Token hết hạn, chuyển hướng đến trang đăng nhập
                navigate('/login');
            }
        } else {
            // Không có accessToken, chuyển hướng đến trang chủ
            navigate('/login');
        }
    }, [navigate, currentPage, loadingBarRef]);

    if (currentPage === 'login') {
        return (

            <div className="dashboard">
                <LoginForm />
            </div>
        );
    }
    const setToast = (type, message) => {
        const newToast = { id: Date.now(), type, message };
        setToasts((prevToasts) => [newToast, ...prevToasts]); // Thêm thông báo mới vào đầu danh sách
    };

    const removeToast = (id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    return (
        <div className="dashboard">
            <div className={`app flex-grow`}>

                <SideBar isVisible={isSidebarVisible} />


                <div className={`app-container flex-grow ${isSidebarVisible ? 'appopen' : 'ml-0'}`}>
                    <div className='container-fluid'>
                        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarVisible} />
                        {currentPage === 'overview' && <OverView setToast={setToast} />}
                        {currentPage === 'proxy' && <Proxy setToast={setToast} />}
                        {currentPage === 'bypass' && <Bypass setToast={setToast} />}
                        {currentPage === 'bypass_login' && <LoginBypass setToast={setToast} />}
                        {currentPage === 'tracking' && <Tracking setToast={setToast} />}
                        {currentPage === 'transactions-history' && <HistoryTransactions setToast={setToast} />}
                        {currentPage === 'members-management' && <MembersManagerment setToast={setToast} />}
                        {currentPage === 'user-analytics' && <UsersAnalytics setToast={setToast} />}
                        {currentPage === 'sites-analytics' && <SitesAnalytics setToast={setToast} />}
                        {currentPage === 'sites-management' && <Managerment setToast={setToast} />}
                        {currentPage === 'employee' && <EmployeeManagerment setToast={setToast} />}
                        {currentPage === 'clickersetting' && <ClickerSetting setToast={setToast} />}
                        {currentPage === 'whitelist' && <Whitelist setToast={setToast} />}
                        {currentPage === 'crypto' && <Crypto setToast={setToast} />}


                    </div>
                    <TimeLine />
                    <ChatBox />
                    <Footer />
                   
                    <div className="fixed space-y-2 shadow-sm bottom-16 right-4">
                        {toasts.map((toast) => (
                            <Toast
                                key={toast.id}
                                type={toast.type}
                                message={toast.message}
                                onClose={() => removeToast(toast.id)}
                            />
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Dashboard;