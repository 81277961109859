import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginBypass = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post('https://bypass.saviartmedia.com/api/login/', {
                username,
                password,
            });

            if (response.data && response.data.access) {
                const { access  } = response.data;
                localStorage.setItem('accessProxy', access);
                navigate('/tool/bypass');
            } else {
                setError('Login failed. No access token provided.');
            }
        } catch (err) {
            console.error('Login error:', err);
            setError('Invalid username or password.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="">
            <div className="flex flex-col items-center justify-center min-h-screen px-4 py-6">
                <div className="flex flex-col items-center justify-center w-full max-w-md gap-4">

                    <img
                        src={process.env.PUBLIC_URL + "/assets/images/logo.svg"}
                        alt=""
                    />


                    <div className="p-8 bg-white shadow rounded-2xl">
                        <h2 className="text-2xl font-bold text-center text-gray-800">Sign in</h2>
                        <form className="mt-8 space-y-4" onSubmit={handleLogin}>

                            <div>
                                <label className="block mb-2 text-sm text-gray-800">User name</label>
                                <div className="relative flex items-center">
                                    <input
                                        name="username"
                                        type="text"
                                        required
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="w-full px-4 py-3 text-sm text-gray-800 border border-gray-300 rounded-md outline-blue-600"
                                        placeholder="Enter user name"
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="block mb-2 text-sm text-gray-800">Password</label>
                                <div className="relative flex items-center">
                                    <input
                                        name="password"
                                        type="password"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full px-4 py-3 text-sm text-gray-800 border border-gray-300 rounded-md outline-blue-600"
                                        placeholder="Enter password"
                                    />
                                </div>
                            </div>


                            <div className="!mt-8">
                                <button
                                    type="submit"
                                    className={`w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none ${loading ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                    disabled={loading}
                                >
                                    {loading ? 'Signing in...' : 'Sign in'}
                                </button>
                               
                            </div>
                            {error && <p className="text-sm text-center text-red-500">{error}</p>}
                            
                            <p className="text-gray-800 text-sm !mt-8 text-center">
                                Don&apos;t have an account?
                                <a
                                    href=""
                                    className="ml-1 font-semibold text-blue-600 hover:underline whitespace-nowrap"
                                >
                                    Register here
                                </a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginBypass;
