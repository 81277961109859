import axios from "axios";

const instance = axios.create({
    baseURL: 'https://bypass.saviartmedia.com/',
});
// Thêm một request interceptor
instance.interceptors.request.use(config => {
    const token = localStorage.getItem('accessProxy');
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Thêm một response interceptor
instance.interceptors.response.use(response => {
    // Xử lý response thông thường
  
    return response.data;
}, error => {
    // Kiểm tra nếu lỗi là do token hết hạn hoặc lỗi xác thực
    if (error.response && error.response.status === 401) {
      
    }
    return Promise.reject(error);
});

export default instance;
