import React, { useState, useEffect } from 'react';
import instance  from '../../../services/axios_bypass';
import moment from 'moment';

const Tracking = ({ setToast }) => {
    const [logs, setLogs] = useState([]);
    const [domainRules, setDomainRules] = useState([]);
    const [wsClient, setWsClient] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [proxyDataBytes, setProxyDataBytes] = useState(0);
    const [proxyByPassBytes, setProxyByPassBytes] = useState(0);
    const [showOnlyProxy, setShowOnlyProxy] = useState(false);
    const [port, setPort] = useState('');
    const [isTracking, setIsTracking] = useState(false);
    const [isWsConnected, setIsWsConnected] = useState(false); 
    const [currentTime, setCurrentTime] = useState(moment()); // Thêm trạng thái thời gian hiện tại
    
  // Cập nhật thời gian hiện tại mỗi giây
  useEffect(() => {
    const timer = setInterval(() => {
        setCurrentTime(moment());
    }, 1000);
    return () => clearInterval(timer); // Dọn dẹp khi component unmount
}, []);
    // Hiển thị Toast
    const handleToast = () => {
        // Hiển thị thông báo Toast từ Dashboard
        setToast({ message: "Tracking action completed!", type: "success" });
    };


    const domainStats = logs.reduce((acc, log) => {
        if (!acc[log.domain]) {
            acc[log.domain] = { upload: 0, download: 0 };
        }
        acc[log.domain].upload += log.uploadBytes;
        acc[log.domain].download += log.downloadBytes;
        return acc;
    }, {});
    // Lấy danh sách domain rules từ API
    useEffect(() => {
        const fetchDomainRules = async () => {
            try {
                const response = await instance.get('/api/domain-rules/');
                // Kiểm tra nếu `response.data` là mảng hợp lệ
                if (Array.isArray(response)) {
                    const rules = response.map(rule => rule.rule); // Trích xuất `rule`
                    setDomainRules(rules);
                } else {
                    console.error('Invalid data format: Expected an array', response.data);
                }
            } catch (error) {
                console.error('Error fetching domain rules:', error);
            }
        };
    
        fetchDomainRules();
    }, []);
    

    const calculatePercentage = (part, total) => {
        if (total === 0) return '0%'; // Tránh chia cho 0
        return `${((part / total) * 100).toFixed(2)}%`;
    };
    // Xử lý WebSocket kết nối
    useEffect(() => {
        let reconnectTimeout;

        const connectWebSocket = () => {
            if (!port || !isTracking) return;

            const ws = new WebSocket(`wss://bypass.saviartmedia.com/ws/logs/${port}/`);

            ws.onopen = () => {
                console.log(`WebSocket connection opened for port: ${port}`);
                setIsWsConnected(true);
            };

            ws.onerror = (error) => {
                console.error(`WebSocket error for port: ${port}`, error);
                setIsWsConnected(false);
            };

            ws.onclose = (event) => {
                console.warn(`WebSocket connection closed for port: ${port}`, event);
                setIsWsConnected(false);
                
            };

            ws.onmessage = handleWebSocketMessage;
            setWsClient(ws);
        };

        connectWebSocket();

        return () => {
            if (wsClient) wsClient.close();
           
        };
    }, [port, isTracking]);



      // Khi thay đổi cổng, dừng việc tracking
      const handlePortChange = (e) => {
        const newPort = e.target.value;
        setPort(newPort);

        // Nếu đang theo dõi, dừng tracking
        if (isTracking) {
            setIsTracking(false);
            setIsWsConnected(false);
            if (wsClient) wsClient.close();
            
        }
    };

    // Xử lý khi nhận message từ WebSocket
    const handleWebSocketMessage = (message) => {
        try {
            const dataFromServer = JSON.parse(message.data).message.trim();
            const logParts = dataFromServer.split(' ');

            if (logParts.length < 11) return;

            const [time, connectType, requestFrom, serverIpPort, uploadBytes, downloadBytes, domain, dPort] = [
                moment(`${logParts[0]} ${logParts[1]}`, 'DD-MM-YYYY HH:mm:ss').format(),
                logParts[2].split(':')[0],
                logParts[4].split(':')[0],
                logParts[5],
                parseInt(logParts[6].split(':')[0], 10),
                parseInt(logParts[6].split(':')[1], 10),
                logParts[9].split(':')[0],
                parseInt(logParts[9].split(':')[1], 10),
            ];

            if (uploadBytes === 0 && downloadBytes === 0) return;

            const bypassData = serverIpPort.startsWith('127.0.0.1') ? 'Direct' : 'Proxy';

            const matchedRule = domainRules.find(rule => {
                if (rule.startsWith('*') && rule.endsWith('*')) return domain.includes(rule.slice(1, -1));
                if (rule.startsWith('*.')) return domain.endsWith(rule.slice(2)) && domain !== rule.slice(2);
                return domain === rule || domain.startsWith(`${rule}/`);
            });

            const newLog = {
                time,
                domain,
                dPort,
                requestFrom,
                uploadBytes,
                downloadBytes,
                upload: formatBytes(uploadBytes),
                download: formatBytes(downloadBytes),
                connectType,
                bypassData,
                rules: bypassData === 'Direct' ? matchedRule || 'None' : 'None',
            };

            if (bypassData === 'Proxy') setProxyDataBytes(prev => prev + uploadBytes + downloadBytes);
            if (bypassData === 'Direct') setProxyByPassBytes(prev => prev + uploadBytes + downloadBytes);

            setLogs(prevLogs => [newLog, ...prevLogs]);
        } catch (error) {
            console.error('Error handling WebSocket message:', error);
        }
    };

    // Format bytes
    const formatBytes = (bytes) => {
        if (bytes === 0) return '0 B';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
    };

    // Sắp xếp logs
    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const sortedLogs = React.useMemo(() => {
        // Áp dụng lọc trước khi sắp xếp
        const displayLogs = showOnlyProxy
            ? logs.filter((log) => log.bypassData === 'Proxy')
            : logs;

        if (!sortConfig.key) return displayLogs;

        return [...displayLogs].sort((a, b) => {
            const valueA = sortConfig.key === 'upload' ? a.uploadBytes :
                sortConfig.key === 'download' ? a.downloadBytes :
                    a[sortConfig.key];
            const valueB = sortConfig.key === 'upload' ? b.uploadBytes :
                sortConfig.key === 'download' ? b.downloadBytes :
                    b[sortConfig.key];

            if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
            if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    }, [logs, sortConfig, showOnlyProxy]);


    const filteredLogs = showOnlyProxy
        ? logs.filter((log) => log.bypassData === 'Proxy')
        : logs;
    // Bắt đầu tracking
    const handleStartTracking = () => {
        if (!port) {
            handleToast('error', 'Please enter a port to start tracking!');
            return;
        }
        setIsTracking(true);
    };
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            handleToast('success', `Copied "${text}" to clipboard!`);
        }).catch((error) => {
            console.error('Failed to copy text: ', error);
            handleToast('error', 'Failed to copy to clipboard.');
        });
    };

    return (
        <div className="w-full px-2 py-2 mt-12">
            <div className="flex items-center w-full gap-4 mb-4">
                <div className="flex-1">
                    <label className="block text-sm font-semibold text-gray-700">
                        Enter Port to Track
                    </label>
                    <p className='mb-5 text-xs '>Please enter the port number you want to monitor. This will start tracking logs and data traffic for the specified port.</p>



                    <div className="flex w-1/3 mb-2">
                        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                            </svg>
                        </span>
                        <input value={port}
                            onChange={handlePortChange} type="text" id="website-admin" className="rounded-none rounded-e-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nhập cổng cần theo dõi ở đây" />
                    </div>

                    <button onClick={handleStartTracking} type="button" className="inline-flex items-center px-5 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Start Tracking
                        <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                    </button>
                </div>

            </div>

            <div className="grid w-full p-1 mb-0 statistics gap-y-5 gap-x-6 md:grid-cols-1 xl:grid-cols-2">
                <div className='grid mb-2 md:grid-cols-1 xl:grid-cols-2 gap-y-5 gap-x-6'>
                    <div className="items-stretch self-stretch justify-between gap-5 pt-2 pl-4 pr-4 bg-white rounded-lg drop-shadow-sm max-md:pr-5">
                        <div className="flex items-center justify-between p-1">
                            <div className="flex">
                                <p className='text-2xl font-bold text-'>
                                    {calculatePercentage(proxyDataBytes, proxyDataBytes + proxyByPassBytes)}
                                </p>

                            </div>
                            <div className="flex-col items-end text-right">
                                <p className="block text-sm font-semibold ">Bandwidth Use</p>
                                <p className="block text-exs ">Dung lượng sử dụng Proxy</p>
                                <h4 className="flex items-center justify-end gap-2 text-xl font-semibold leading-snug text-emerald-600/">
                                    {formatBytes(proxyDataBytes)}


                                </h4>

                            </div>
                        </div>
                        <div className="p-1 border-t">
                            <p className="block font-sans text-sm antialiased font-normal leading-relaxed ">

                            </p>
                        </div>
                    </div>
                    <div className="items-stretch self-stretch justify-between gap-5 pt-2 pl-4 pr-4 bg-white rounded-lg drop-shadow-sm max-md:pr-5">
                        <div className="flex items-center justify-between p-1">
                            <div className="flex">
                                <p className='text-2xl font-bold text-'>
                                    {calculatePercentage(proxyByPassBytes, proxyDataBytes + proxyByPassBytes)}
                                </p>

                            </div>
                            <div className="flex-col items-end text-right">
                                <p className="block text-sm font-semibold ">Bandwidth Bypass Use</p>
                                <p className="block text-exs ">Dung lượng đã được Bypass</p>
                                <h4 className="flex items-center justify-end gap-2 text-xl font-semibold leading-snug text-emerald-600/">
                                    {formatBytes(proxyByPassBytes)}


                                </h4>
                            </div>
                        </div>
                        <div className="p-1 border-t">
                            <p className="block font-sans text-sm antialiased font-normal leading-relaxed ">

                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-start'>

                    <label className="inline-flex items-center mb-5 cursor-pointer ">
                        <input type="checkbox" value="" onChange={(e) => setShowOnlyProxy(e.target.checked)} className="sr-only peer" />
                        <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-3 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="text-xs font-medium text-gray-900 ms-3 dark:text-gray-300">Display Only Traffic From Proxy</span>
                    </label>
                    {isWsConnected && (<div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                        <span className="font-medium">Success Tracking!</span> Đang theo dõi dữ liệu từ cổng: {port}
                    </div>)}

                </div>
            </div>


            <div className="h-[480px] overflow-auto border rounded-lg shadow  ">
                <table id="bandwidth-table" className="relative min-w-full bg-white divide-y divide-gray-200 dark:divide-neutral-700 ">
                    <thead className="bg-gray-50 dark:bg-neutral-700">
                        <tr>
                            <th onClick={() => handleSort('domain')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                Domain {sortConfig.key === 'domain' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('port')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                D.Port {sortConfig.key === 'port' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('requestFrom')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                Request From {sortConfig.key === 'requestFrom' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('upload')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                Upload {sortConfig.key === 'upload' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('download')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                Download {sortConfig.key === 'download' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>

                            <th onClick={() => handleSort('connectType')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                Connect {sortConfig.key === 'connectType' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('bypassData')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                Bypass Data {sortConfig.key === 'bypassData' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('rules')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                Rules {sortConfig.key === 'rules' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('time')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                Time {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>

                        </tr>
                    </thead>
                    <tbody className='bg-white '>

                        {sortedLogs.map((log, index) => (
                            <tr key={index} className="font-mono bg-white border border-dashed border-e-gray-400 ">
                                <td
                                    className="relative px-4 py-1 text-gray-900 whitespace-nowrap dark:text-white group"
                                    onClick={() => handleCopyToClipboard(log.domain)}>
                                    {/* Domain với Tooltip */}
                                    <span className="cursor-pointer group-hover:text-blue-500">{log.domain}</span>
                                    {/* Tooltip */}
                                    <div
                                        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                                        style={{ top: '100%', left: '50%', transform: 'translateX(-50%)', marginTop: '4px' }}
                                    >
                                        <p>
                                            <b>Upload:</b> {formatBytes(domainStats[log.domain]?.upload)}
                                        </p>
                                        <p>
                                            <b>Download:</b> {formatBytes(domainStats[log.domain]?.download)}
                                        </p>
                                    </div>
                                </td>
                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.dPort}</td>
                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.requestFrom}</td>
                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.upload}</td>
                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.download}</td>
                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.connectType}</td>
                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">
                                    {log.bypassData === "Proxy" ? (
                                        <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                            Proxy
                                        </span>
                                    ) : (
                                        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                            Direct
                                        </span>
                                    )}
                                </td>
                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.rules}</td>
                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white"> {moment(log.time).from(currentTime)}</td>

                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            
        </div>
    );
    
};

export default Tracking;
