import React, { useState, useEffect } from 'react';
import instance  from '../../../services/axios_bypass';
import Modaltracking from './modaltracking';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


function Bypass({ setToast }) {
    // Sử dụng state để theo dõi thứ tự của các component
    const navigate = useNavigate();
    const [proxies, setProxies] = useState('');
    const [domainRules, setDomainRules] = useState('');
    const [whitelist, setWhitelist] = useState('');
    const [proxyData, setProxyData] = useState([]);
    const [exDomain, setexDomain] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const proxiesPerPage = 8;
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentPort, setCurrentPort] = useState(null);

    const [bandwidthData, setBandwidthData] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: 'time', direction: 'desc' });

    const [isTracking, setIsTracking] = useState(false); // Trạng thái log tracking
    const [loading, setLoading] = useState(false); // Trạng thái loading để ngăn thao tác liên tục
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState('');
    const [confirmModalAction, setConfirmModalAction] = useState(null); // Để lưu hành động khi nhấn Yes

    const showConfirmModal = (message, action) => {
        setConfirmModalMessage(message);
        setConfirmModalAction(() => action); // Lưu hành động
        setIsConfirmModalOpen(true);
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setConfirmModalMessage('');
        setConfirmModalAction(null);
    };

    const confirmAction = () => {
        if (confirmModalAction) {
            confirmModalAction(); // Thực hiện hành động khi người dùng nhấn Yes
        }
        closeConfirmModal();
    };

    useEffect(() => {
        const token = localStorage.getItem('accessProxy');
        if (!token) {
            navigate('/tool/login');
        } else {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                if (decodedToken.exp < currentTime) {
                    localStorage.removeItem('accessProxy');
                    navigate('/tool/login');
                }
            } catch (error) {
                localStorage.removeItem('accessProxy');
                navigate('/tool/login');
            }
        }
    }, [navigate]);
    
    const cleanInput = (input) => {
        return input
            .split('\n') // Tách từng dòng
            .map(line => line.trim()) // Loại bỏ khoảng trắng thừa đầu và cuối dòng
            .filter(line => line !== '') // Loại bỏ dòng trống
            .join('\n'); // Ghép lại thành chuỗi với dấu xuống dòng
    };
    const formatSize = (size) => {
        if (size >= 1024 * 1024) {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        } else if (size >= 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else {
            return `${size} B`;
        }
    };

    const fetchLogTrackingStatus = async () => {
        const token = localStorage.getItem('accessProxy');
        if (!token) {
            
            return;
        }
    
        try {
            const response = await instance.get('/api/logtrack/status/');
            const isRunning = response.message.includes('running');
            setIsTracking(isRunning);
        } catch (error) {
            
        }
    };
    useEffect(() => {
        // Kiểm tra trạng thái log tracking khi component được mount
        fetchLogTrackingStatus();
    }, []);

    const handleAddDomainAction = (domain) => {
        const domainList = domainRules.split('\n').map(item => item.trim()); // Tách danh sách domain hiện tại
        if (!domainList.includes(domain)) {
            const updatedDomains = [...domainList, domain].join('\n'); // Thêm domain mới
            setDomainRules(updatedDomains); // Cập nhật state
            setToast('success', `Domain "${domain}" đã được thêm thành công!`);
        } else {
            setToast('error', `Domain "${domain}" đã tồn tại trong danh sách.`);
        }
    };

    const toggleLogTracking = async () => {
        setLoading(true);
        try {
            if (isTracking) {
                await instance.post('/api/logtrack/stop');
            } else {
                await instance.post('/api/logtrack/start');
            }
            setIsTracking(!isTracking);
        } catch (error) {

        }
        setLoading(false);
    };



    const handleClearAll = () => {
        showConfirmModal(
            'Are you sure you want to delete all bandwidth records?',
            async () => {
                try {
                    await instance.post('/api/bandwidth/');
                    setBandwidthData([]); // Clear local state
                    setToast('success', 'All bandwidth records deleted successfully!');
                } catch (error) {

                    setToast('error', 'Failed to clear all bandwidth records.');
                }
            }
        );
    };
    


    const openModal = (port) => {
        setCurrentPort(port);
        setModalOpen(true);
    };

    // Hàm đóng modal
    const closeModal = () => {
        setModalOpen(false);
        setCurrentPort(null); // Reset proxy hiện tại
    };
    const handleAddDomain = (domain) => {
        const domainList = domainRules.split('\n');
        if (!domainList.includes(domain)) {
            const updatedDomains = [...domainList, domain].join('\n');
            setDomainRules(updatedDomains);
            return true; // Domain được thêm thành công
        }
        return false; // Domain đã tồn tại
    };

    const fetchConfiguration = async () => {
    const token = localStorage.getItem('accessProxy');
    if (!token) {
        return;
    }

    try {
        const proxyResponse = await instance.get('/api/proxies/');
        const proxyDataFormatted = proxyResponse.map((proxy, index) => ({
            ...proxy,
            exportPort: 10001 + index,
        }));
        const proxyDataString = proxyDataFormatted
            .map(proxy =>
                `${proxy.ip}:${proxy.port}${proxy.username ? `:${proxy.username}:${proxy.password}` : ''}`
            )
            .join('\n');

        const domainRulesResponse = await instance.get('/api/domain-rules/');
        const domainRulesData = domainRulesResponse.map(rule => rule.rule).join('\n');

        const whitelistResponse = await instance.get('/api/whitelist/');
        const whitelistData = whitelistResponse.map(item => item.ip).join('\n');

        const exDomainResponse = await instance.get('/api/exbandwidth/');
        const exDomainList = exDomainResponse.map(item => item.domain).join('\n');

        const bandwidthResponse = await instance.get('/api/bandwidth/');

        setProxies(proxyDataString);
        setDomainRules(domainRulesData);
        setWhitelist(whitelistData);
        setProxyData(proxyDataFormatted);
        setBandwidthData(bandwidthResponse);
        setexDomain(exDomainList);
    } catch (error) {
        setToast('error', 'Lỗi khi tải dữ liệu');
    }
};
    useEffect(() => {
        fetchConfiguration();
    }, []);

    const handleUpdateConfiguration = () => {
        // Hiển thị modal xác nhận trước khi thực hiện
        showConfirmModal(
            'Are you sure you want to update the configuration?',
            async () => {
                const cleanedProxies = cleanInput(proxies);
                const cleanedDomainRules = cleanInput(domainRules);
                const cleanedWhitelist = cleanInput(whitelist);
    
                const proxiesArray = cleanedProxies.split('\n').map((line) => {
                    const [ip, port, username, password] = line.split(':');
                    return { ip, port: parseInt(port, 10), username, password };
                });
    
                const domainRulesArray = cleanedDomainRules.split('\n').map((rule) => ({ rule }));
                const whitelistArray = cleanedWhitelist.split('\n').map((ip) => ({ ip }));
    
                try {
                    const response = await instance.post('/api/update-configuration/', {
                        proxies: proxiesArray,
                        domain_rules: domainRulesArray,
                        whitelist: whitelistArray,
                    });

                    if (response.message === 'success') {
                        setToast('success', 'Cấu hình cập nhật thành công!');
                        fetchConfiguration();
                    } else {
                        setToast('error', 'Cập nhật cấu hình thất bại');
                    }
                } catch (error) {
                    setToast('error', 'Lỗi khi cập nhật cấu hình');
                }
            }
        );
    };
    

    // Calculate the displayed proxies for the current page
    const indexOfLastProxy = currentPage * proxiesPerPage;
    const indexOfFirstProxy = indexOfLastProxy - proxiesPerPage;
    const currentProxies = proxyData.slice(indexOfFirstProxy, indexOfLastProxy);

    // Handle pagination
    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(proxyData.length / proxiesPerPage)));
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };
    const sortedBandwidthData = React.useMemo(() => {
        if (!sortConfig.key) return bandwidthData;

        return [...bandwidthData].sort((a, b) => {
            const valueA = sortConfig.key === 'upload' || sortConfig.key === 'download'
                ? a[sortConfig.key]
                : a[sortConfig.key]?.toString().toLowerCase();
            const valueB = sortConfig.key === 'upload' || sortConfig.key === 'download'
                ? b[sortConfig.key]
                : b[sortConfig.key]?.toString().toLowerCase();

            if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
            if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    }, [bandwidthData, sortConfig]);
    const handleSaveExDomain = () => {
        showConfirmModal(
            'Are you sure you want to save the excluded domains?',
            async () => {
                try {
                    const exDomainArray = exDomain
                        .split('\n')
                        .map((domain) => domain.trim())
                        .filter((domain) => domain !== '');
    
                    const response = await instance.post(
                        '/api/exbandwidth/',
                        exDomainArray.map((domain) => ({ domain }))
                    );
    
                    // Kiểm tra response.message
                    if (response.message === 'success') {
                        setToast('success', 'Danh sách domain đã được lưu thành công!');
                    } else {
                        setToast('error', 'Đã xảy ra lỗi khi lưu danh sách domain.');
                    }
                } catch (error) {

                    setToast('error', 'Lỗi khi lưu danh sách domain.');
                }
            }
        );
    };
    


    return (
        <div className="mt-20">
            <div className="statistics">
                <div className="pl-5">
                    <div className="font-semibold">
                        ByPass Proxy Server
                    </div>
                    <div className="text-xs">
                        Cấu hình máy chủ ByPass Proxy
                    </div>
                </div>


            </div>




            <div className="w-full charttrend">
                <div className="p-2">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="...">
                            <div className='p-2 bg-white rounded-lg'>
                                <div className='p-2 text-sm font-semibold border-b border-gray-200'>
                                    Danh sách Whitelist
                                </div>
                                <div className="p-1 text-xs">
                                    (One rule per line)
                                </div>

                                <textarea
                                    rows="5"
                                    cols="100"
                                    placeholder="Enter IPs whitelist, one per line"
                                    value={whitelist}
                                    onChange={(e) => setWhitelist(e.target.value)}
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />



                            </div>
                        </div>
                        <div className="...">
                            <div className='p-2 bg-white rounded-lg'>
                                <div className='p-2 text-sm font-semibold border-b border-gray-200'>
                                    Danh sách Domain
                                </div>
                                <div className="p-1 text-xs">
                                    (One rule per line)
                                </div>

                                <textarea
                                    rows="5"
                                    cols="100"
                                    placeholder="Enter IPs whitelist, one per line"
                                    value={domainRules}
                                    onChange={(e) => setDomainRules(e.target.value)}
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />



                            </div>
                        </div>
                        <div className="col-span-1">

                            <div className='p-2 bg-white rounded-lg'>
                                <div className='p-2 text-sm font-semibold border-b border-gray-200'>
                                    Danh sách Proxy
                                </div>
                                <div className="p-1 text-xs">
                                    (One rule per line)
                                </div>

                                <textarea
                                    rows="16"
                                    cols="100"
                                    placeholder="Enter IPs whitelist, one per line"
                                    value={proxies}
                                    onChange={(e) => setProxies(e.target.value)}
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />



                            </div>

                        </div>

                        <div className="col-span-1 ">
                            <div className='tracking '>
                                <div className="flex flex-col">
                                    <div className="overflow-x-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="inline-block min-w-full align-middle">
                                            <div className="overflow-hidden">
                                                <table className="w-full divide-y divide-gray-200 dark:divide-neutral-700">
                                                    <thead className="bg-gray-50 dark:bg-neutral-700">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start dark:text-neutral-500">Proxies</th>
                                                            {/* <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start dark:text-neutral-500">Root</th> */}
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-end dark:text-neutral-500">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                                                        {currentProxies.map((proxy, index) => (
                                                            <tr key={index}>
                                                                <td className="px-6 py-2 text-sm text-gray-800 whitespace-nowrap dark:text-neutral-200">
                                                                    27.72.156.108:{proxy.exportPort}
                                                                </td>
                                                                {/* <td className="px-6 py-4 text-xs text-gray-800 whitespace-nowrap dark:text-neutral-200">
                                                                    {proxy.ip}:{proxy.port}{proxy.username ? `:${proxy.username}:${proxy.password}` : ''}
                                                                </td> */}
                                                                <td className="px-6 py-2 whitespace-nowrap text-end">
                                                                    <button
                                                                        onClick={() => openModal(proxy.exportPort)}
                                                                        className="inline-flex items-center text-xs font-semibold text-blue-600 border border-transparent rounded-lg gap-x-2 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400"
                                                                    >
                                                                        Tracking
                                                                    </button>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="px-4 py-1">
                                                    <nav className="flex items-center space-x-1">
                                                        <button
                                                            type="button"
                                                            onClick={handlePreviousPage}
                                                            disabled={currentPage === 1}
                                                            className="p-2.5 w-[40px] inline-flex justify-center items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10"
                                                        >
                                                            <span aria-hidden="true">«</span>
                                                            <span className="sr-only">Previous</span>
                                                        </button>

                                                        <button
                                                            type="button"
                                                            onClick={handleNextPage}
                                                            disabled={currentPage === Math.ceil(proxyData.length / proxiesPerPage)}
                                                            className="p-2.5 min-w-[40px] inline-flex justify-center items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10"
                                                        >
                                                            <span className="sr-only">Next</span>
                                                            <span aria-hidden="true">»</span>
                                                        </button>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="flex items-center justify-center w-full py-2 border-t dark:border-gray-600">
                                <button onClick={handleUpdateConfiguration} className=" w-3/4 items-center py-2.5 px-4 text-xs font-semibold  text-white bg-orange-500 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">
                                    Lưu Cấu Hình
                                </button>

                            </div>
                        </div>


                    

                    </div>
                    {isModalOpen && (
                        <Modaltracking
                            exportPort={currentPort}
                            onAddDomain={handleAddDomain} // Truyền hàm thêm domain
                            onClose={closeModal}
                        />
                    )}
                    <div className='grid grid-cols-2 gap-4'>
                        <div className="col-span-2 ">
                            <div className='p-2 mb-5 bg-white rounded-lg'>
                                <div className='p-2 text-sm font-semibold border-b border-gray-200'>
                                    Danh sách các domain lưu lượng cao qua PROXY (Upload hoặc Download) lớn hơn 1MB
                                </div>
                                <div className="p-1 text-xs">
                                    (Danh sách domain đưuọc loại trừ sẽ không bị tính vào về sau nữa )
                                </div>

                                <textarea
                                    rows="5"
                                    cols="100"
                                    placeholder="Enter Except Domains, one per line"
                                    value={exDomain} // Liên kết với state exDomain
                                    onChange={(e) => setexDomain(e.target.value)} // Cập nhật state khi người dùng chỉnh sửa
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />

                            </div>
                            <div className="flex items-center justify-center w-full py-2 border-t dark:border-gray-600">
                                <button onClick={handleSaveExDomain} className=" w-2/4 items-center py-2.5 px-4 text-xs font-semibold  text-white bg-green-500 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">
                                    Lưu danh sách domain
                                </button>


                            </div>
                        <div className='flex justify-between'>
                            <div className='flex flex-col items-start'>

                                <label className="inline-flex items-center mb-5 cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="sr-only peer"
                                        checked={isTracking}
                                        onChange={toggleLogTracking}
                                        disabled={loading} // Ngăn thao tác khi API đang xử lý
                                    />
                                    <div className={`relative w-11 h-6 rounded-full ${isTracking
                                            ? 'peer-checked:bg-blue-600 bg-gray-200'
                                            : 'bg-gray-200 dark:bg-gray-700'
                                        }`}>
                                        <span
                                            className={`absolute top-[2px] start-[2px] rounded-full w-5 h-5 transition-all ${isTracking
                                                    ? 'translate-x-full bg-white'
                                                    : 'translate-x-0 bg-white'
                                                }`}
                                        ></span>
                                    </div>
                                    <span className="font-semibold text-gray-900 ms-3 text-dm dark:text-gray-300">
                                        Realtime Bandwidth PROXY
                                    </span>
                                </label>


                            </div>

                            <button onClick={handleClearAll} type="button" className="px-3 py-1 mb-2 text-sm font-medium text-center text-red-700 border border-red-700 rounded-lg hover:text-white hover:bg-red-800 focus:ring-2 focus:outline-none focus:ring-red-300 me-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">Clear All</button>

                        </div>
                            <div className="h-[480px] overflow-auto border rounded-lg shadow  ">
                                <table id="bandwidth-table" className="relative min-w-full bg-white divide-y divide-gray-200 dark:divide-neutral-700 ">
                                    <thead className="bg-gray-50 dark:bg-neutral-700">
                                        <tr>
                                            <th
                                                onClick={() => handleSort('domain')}
                                                className="px-4 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start"
                                            >
                                                Domain {sortConfig.key === 'domain' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('request_from')}
                                                className="px-4 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start"
                                            >
                                                Request From {sortConfig.key === 'request_from' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('portrequest')}
                                                className="px-4 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start"
                                            >
                                                Port {sortConfig.key === 'portrequest' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('connect')}
                                                className="px-4 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start"
                                            >
                                                Connect {sortConfig.key === 'connect' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('upload')}
                                                className="px-4 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start"
                                            >
                                                Upload (MB) {sortConfig.key === 'upload' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('download')}
                                                className="px-4 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start"
                                            >
                                                Download (MB) {sortConfig.key === 'download' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th
                                                onClick={() => handleSort('time')}
                                                className="px-4 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start"
                                            >
                                                Time {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th
    className="px-4 py-3 text-xs font-medium text-gray-500 uppercase text-start"
>
    Action
</th>
                                        </tr>
                                    </thead>
                                    <tbody className='bg-white '>
                                        {sortedBandwidthData.map((item, index) => (
                                            <tr key={index} className="font-mono bg-white border border-dashed border-e-gray-400">
                                                <td className="px-4 py-1 text-gray-900 whitespace-nowrap dark:text-white">{item.domain}</td>
                                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{item.request_from}</td>
                                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{item.portrequest}</td>
                                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{item.connect}</td>
                                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">
                                                    {formatSize(item.upload)}
                                                </td>
                                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">
                                                    {formatSize(item.download)}
                                                </td>
                                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">
                                                    {new Date(item.time).toLocaleString()}
                                                </td>
                                                <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">
    <button
        onClick={() => handleAddDomainAction(item.domain)}
        className="px-3 py-1 text-xs font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-700"
    >
        Add
    </button>
</td>
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {isConfirmModalOpen && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative w-full max-w-md max-h-full p-4 bg-white rounded-lg shadow dark:bg-gray-700">
            <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={closeConfirmModal}
            >
                <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
            <div className="p-4 text-center">
                <svg
                    className="w-12 h-12 mx-auto mb-4 text-gray-400 dark:text-gray-200"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    {confirmModalMessage}
                </h3>
                <button
                    onClick={confirmAction}
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 mr-2"
                >
                    Yes, I'm sure
                </button>
                <button
                    onClick={closeConfirmModal}
                    className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                    No, cancel
                </button>
            </div>
        </div>
    </div>
)}

                </div>
            </div>
        </div>
    );
}

export default Bypass;
